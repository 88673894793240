@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);

/* print pr modal (from convert excel to html tool) - start */
@page {
    mso-header-data: "";
    mso-footer-data: "&L&\0022-,Italic\0022&8Rev. No: 4\000A&C&\0022-,Italic\0022&8\000AEff. Date: 25/03/2024\000A&R&\0022-,Italic\0022&8ATSB/FR/FI-0002\000APage 1 of 1";
    margin: 0.748031496062992in 0.708661417322835in 0.748031496062992in 0.708661417322835in;
    mso-header-margin: 0.31496062992126in;
    mso-footer-margin: 0.31496062992126in;
    mso-page-orientation: Portrait;
    mso-horizontal-page-align: center;
}

.page-footer, .page-footer-space {
    height: 50px;
  }

.page-footer {
    font-size: 8px;
    color: #000;
    display: flex;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    font-style: italic;
    margin-top: 30px;
}

.footer-left,
.footer-center,
.footer-right {
    flex: 1;
    box-sizing: border-box;
}

@page {
    size: A4;
    margin: 19mm 18mm 19mm 18mm;
}

@media print {
    .page-footer {
        margin-top: 50px;
        position: fixed;
        bottom: 0;
    }

    .content-block,
    p {
        page-break-inside: avoid;
    }

    html,
    body {
        width: 210mm;
        height: 297mm;
    }
}


tr {
    mso-height-source: auto;
    mso-ruby-visibility: none;
}

col {
    mso-width-source: auto;
    mso-ruby-visibility: none;
}

br {
    mso-data-placement: same-cell;
}

ruby {
    ruby-align: left;
}

.style0 {
    mso-number-format: General;
    text-align: general;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
    mso-style-name: Normal;
    mso-style-id: 0;
}

td {
    mso-style-parent: style0;
    mso-number-format: General;
    text-align: general;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
    mso-ignore: padding;
}

.x15 {
    mso-number-format: General;
    text-align: general;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x21 {
    mso-number-format: General;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x22 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x23 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: italic;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x24 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x25 {
    mso-number-format: '0\.00';
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #BFBFBF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: none;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x26 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #BFBFBF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x27 {
    mso-number-format: General;
    text-align: general;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x28 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x29 {
    mso-number-format: General;
    text-align: general;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x30 {
    mso-number-format: '_-* \#\,\#\#0\.00_-\;\\-* \#\,\#\#0\.00_-\;_-* \0022-\0022??_-\;_-\@_-';
    text-align: general;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x31 {
    mso-number-format: '\#\,\#\#0\.00_\)\;\(\#\,\#\#0\.00\)';
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x32 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x33 {
    mso-number-format: '\#\,\#\#0\.00_\)\;\(\#\,\#\#0\.00\)';
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x34 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x35 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x36 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #FFFFFF;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x37 {
    mso-number-format: '\#\,\#\#0\.00_\)\;\(\#\,\#\#0\.00\)';
    text-align: general;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x38 {
    mso-number-format: General;
    text-align: general;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x39 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x40 {
    mso-number-format: General;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x41 {
    mso-number-format: '\#\,\#\#0\.00_\)\;\(\#\,\#\#0\.00\)';
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x42 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x43 {
    mso-number-format: General;
    padding-left: 5px;    
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x44 {
    mso-number-format: General;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x45 {
    mso-number-format: General;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x46 {
    mso-number-format: General;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #FFFFFF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x47 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #FFFFFF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x48 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #FFFFFF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x49 {
    mso-number-format: 'MM\/dd\/yyyy';
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x50 {
    mso-number-format: 'MM\/dd\/yyyy';
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x51 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x52 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x53 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x54 {
    mso-number-format: General;
    padding-left: 5px;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x55 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 14pt;
    font-weight: 700;
    font-style: normal;
    text-decoration: underline;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x56 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #BFBFBF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x57 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #BFBFBF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x58 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #BFBFBF;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x59 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x60 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x61 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x62 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x63 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: italic;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x64 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x65 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x66 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x67 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    /* white-space: nowrap; */
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
    white-space: pre-wrap;
    word-break: break-word;
}

.x68 {
    mso-number-format: General;
    text-align: left;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x69 {
    mso-number-format: General;
    text-align: left;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x70 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x71 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x72 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: none;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x73 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x74 {
    mso-number-format: General;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    background: #000000;
    mso-pattern: auto none;
    color: #FFFFFF;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x75 {
    mso-number-format: General;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    background: #000000;
    mso-pattern: auto none;
    color: #FFFFFF;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x76 {
    mso-number-format: General;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    background: #000000;
    mso-pattern: auto none;
    color: #FFFFFF;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x77 {
    mso-number-format: General;
    padding-left: 5px;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x78 {
    mso-number-format: General;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x79 {
    mso-number-format: General;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: 1px solid windowtext;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x80 {
    mso-number-format: General;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x81 {
    mso-number-format: General;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x82 {
    mso-number-format: General;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: 1px solid windowtext;
    border-bottom: 1px solid windowtext;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x83 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x84 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: none;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x85 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    border-right: 1px solid windowtext;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x86 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x87 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    mso-protection: locked visible;
}

.x88 {
    mso-number-format: General;
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
    background: auto;
    mso-pattern: auto;
    color: #000000;
    font-size: 11pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: none;
    border-right: 1px solid windowtext;
    border-bottom: none;
    border-left: none;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x89 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    /* border-right:1px solid windowtext; */
    border-bottom: 1px solid windowtext;
    border-left: 1px solid windowtext;
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

.x90 {
    mso-number-format: General;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    background: #A6A6A6;
    mso-pattern: auto none;
    color: #000000;
    font-size: 11pt;
    font-weight: 700;
    font-style: normal;
    font-family: Calibri, sans-serif;
    border-top: 1px solid windowtext;
    /* border-right:1px solid windowtext; */
    /* border-bottom:1px solid windowtext; */
    /* border-left:1px solid windowtext; */
    mso-diagonal-down: none;
    mso-diagonal-up: none;
    mso-protection: locked visible;
}

/* print pr modal - end */

/* root styles - dark mode */
:root[data-theme="dark"] {
    --bs-body-bg: #141b2d;
    --text-primary: #fff;
    --text-secondary: grey;
    --background-color-card: rgb(31, 41, 63);
    --form-control: #283247;
    --border-top: #1f293f;
    --card-header-bg: #141b2d;
    --sidebar-bg: #1f293f;
    --sidebar-menu-ul-li-a-text-color: #fff;
    --vertical-collapse-sidebar-menu-text-color: #fff;
    --btn: #fff;
    --dropdown-item-hover: #525ce5c5;
    --body-bg: #141b2d;
    --heading-color: #fff;
    --bs-footer-bg: #1f293f;
    transition: all 0.5s;
}

/* App.js - default style */
.app {
    color: var(--text-primary);
    background-color: var(--bs-body-bg);
}

/* header component */
#page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1001;
    background-color: var(--border-top);
}

/* helper text - justification section */
#caption-justification {
    font-size: smaller;
    padding-left: 4px;
    font-style: italic;
}

/* helper text - other inputs in form */
#caption-form {
    font-size: small;
    font-style: italic;
    margin-top: 20px;
}

/* supplier details component - in purchase details page */
#supplier-details-component i {
    font-size: x-large;
    color: #525ce5e0;
}

/* word wrap - for react-data-table-component cells */
.word-wrap {
    white-space: pre-wrap;
    word-break: break-word;
}

/* spinner grey background */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
}

/* pr status label - at purchase detail page */
.label-status {
    font-size: small;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

/* list of uploaded files */
.file-list {
    list-style: none;
    margin-bottom: 10px;
    padding: 5px 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* list of uploaded files - bullet point */
.file-list i {
    font-size: 25px;
    margin-right: 10px;
}

/* error message - at form */
.error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    font-size: .8em;
}

/* navbar */
.navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    height: 70px;
    padding: 0 calc(24px / 2) 0 0;
    -webkit-box-shadow: 0 2px 4px rgba(126, 142, 177, .1);
    box-shadow: 0 2px 4px rgba(126, 142, 177, .1)
}

/* navbar - dropdown menu */
.navbar-header .dropdown.show .header-item {
    background-color: var(--bs-body-bg);
}

/* navbar - logo */
.navbar-brand-box {
    padding: 1.5rem;
    text-align: center;
    width: 260px
}

/* logo - start */
.logo {
    line-height: 70px
}

.logo .logo-sm {
    display: none
}

.logo-light {
    display: none
}

/* logo - end */

/* wrapper */
.page-content-wrapper {
    margin-top: -90px;
}

/* page title + breadcrumbs area - start */
.page-title-box {
    background: url(../images/title-img.png);
    background-position: center;
    background-color: #525ce5;
    margin: 0 -24px 23px -24px;
    padding: 25px 24px 92px 24px;
    color: #fff;
    background-size: cover
}

.page-title-box .breadcrumb {
    background-color: transparent;
    padding: 0
}

.page-title-box h4 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px !important
}

/* page title + breadcrumbs area - end */

/* page content */
.page-content {
    padding: calc(70px) calc(24px / 2) 60px calc(24px / 2)
}

/* header items - sidebar button, theme mode & logout button - start*/
.header-item {
    height: 70px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #636e75;
    border: 0;
    border-radius: 0
}

.header-item:hover {
    color: #636e75
}

.header-profile-user {
    height: 36px;
    width: 36px;
    background-color: #eaedf1;
    padding: 3px
}

/* header items - sidebar button, theme mode & logout button - end*/

/* footer - start */
.footer {
    bottom: 0;
    padding: 20px calc(24px / 2);
    position: absolute;
    right: 0;
    border-top: 1px solid var(--border-top);
    color: #74788d;
    left: 260px;
    -webkit-box-shadow: 0 2px 4px rgba(126, 142, 177, .1);
    box-shadow: 0 2px 4px rgba(126, 142, 177, .1);
    background-color: var(--bs-footer-bg);
}

.vertical-collpsed .footer {
    left: 70px
}

/* footer - end */

/* metismenu - left side bar - start */
.metismenu {
    margin: 0
}

.metismenu li {
    display: block;
    width: 100%
}

.metismenu .mm-collapse {
    display: none
}

.metismenu .mm-collapse:not(.mm-show) {
    display: none
}

.metismenu .mm-collapse.mm-show {
    display: block
}

.metismenu .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility
}

.vertical-menu {
    width: 260px;
    z-index: 1001;
    background: var(--sidebar-bg);
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    -webkit-box-shadow: 0 2px 4px rgba(126, 142, 177, .1);
    box-shadow: 0 2px 4px rgba(126, 142, 177, .1)
}

#sidebar-menu {
    padding: 20px 0 0px 0
}

#sidebar-menu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

#sidebar-menu .has-arrow:after {
    content: "\f0142";
    font-family: "Material Design Icons";
    display: block;
    float: right;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 1rem
}

#sidebar-menu ul li a {
    display: block;
    padding: .625rem 1.2rem;
    color: var(--sidebar-menu-ul-li-a-text-color);
    position: relative;
    font-size: 14.5px;
    -webkit-transition: all .4s;
    transition: all .4s;
    margin: 0 17px;
    border-radius: 3px
}

#sidebar-menu ul li a i {
    display: inline-block;
    min-width: 1.75rem;
    padding-bottom: .125em;
    font-size: 16px;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: var(--sidebar-menu-ul-li-a-text-color);
    -webkit-transition: all .4s;
    transition: all .4s
}

#sidebar-menu ul li a:hover {
    color: #525ce5
}

#sidebar-menu ul li a:hover i {
    color: #525ce5
}

#sidebar-menu ul li .badge {
    margin-top: 5px
}

#sidebar-menu ul li ul.sub-menu {
    padding: 0
}

#sidebar-menu ul li ul.sub-menu li a {
    padding: .4rem 1.5rem .4rem 2.8rem;
    font-size: 14px;
    color: var(--sidebar-menu-ul-li-a-text-color);
    background-color: transparent !important
}

#sidebar-menu ul li ul.sub-menu li a:before {
    content: "\f09df";
    font-family: "Material Design Icons";
    font-size: 20px;
    line-height: 10px;
    padding-right: 2px;
    vertical-align: middle;
    display: inline-block
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
    padding: 0
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
    padding: .4rem 1.5rem .4rem 4rem;
    font-size: 14px
}

.mm-active {
    color: var(--bs-blue) !important
}

.mm-active>a {
    color: var(--bs-blue) !important;
    background-color: #f5f7fa !important
}

.mm-active>a i {
    color: var(--bs-blue) !important
}

.mm-active>i {
    color: var(--bs-blue) !important
}

.mm-active .active {
    color: var(--bs-blue) !important;
    background-color: #f5f7fa !important
}

.mm-active .active i {
    color: var(--bs-blue) !important
}

.vertical-collpsed .main-content {
    margin-left: 70px;
}

.vertical-collpsed .navbar-brand-box {
    width: 70px !important
}

.vertical-collpsed .logo span.logo-lg {
    display: none
}

.vertical-collpsed .logo span.logo-sm {
    display: block
}

.vertical-collpsed .vertical-menu {
    position: absolute;
    width: 70px !important;
    z-index: 5
}

.vertical-collpsed .vertical-menu .simplebar-content-wrapper,
.vertical-collpsed .vertical-menu .simplebar-mask {
    overflow: visible !important
}

.vertical-collpsed .vertical-menu .simplebar-scrollbar {
    display: none !important
}

.vertical-collpsed .vertical-menu .simplebar-offset {
    bottom: 0 !important
}

.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in,
.vertical-collpsed .vertical-menu #sidebar-menu .menu-title {
    display: none !important
}

.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
    height: inherit !important
}

.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
    display: none
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li {
    position: relative;
    white-space: nowrap
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    padding: 15px 20px;
    min-height: 55px;
    -webkit-transition: none;
    transition: none;
    margin: 0
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:active,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:focus,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:hover {
    color: var(--vertical-collapse-sidebar-menu-text-color);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
    font-size: 1.15rem;
    margin-left: 4px
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
    display: none;
    padding-left: 25px
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
    position: relative;
    width: calc(190px + 70px);
    background-color: var(--sidebar-bg);
    -webkit-transition: none;
    transition: none
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a span {
    display: inline
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul {
    display: block;
    left: 70px;
    position: absolute;
    width: 190px;
    height: auto !important;
    -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
    box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1)
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul ul {
    -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
    box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1)
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8px 20px;
    position: relative;
    width: 190px;
    z-index: 6;
    color: #27303f;
    margin: 0
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover {
    color: #525ce5
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul {
    padding: 5px 0;
    z-index: 9999;
    display: none;
    background-color: #fff
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li:hover>ul {
    display: block;
    left: 190px;
    height: auto !important;
    margin-top: -36px;
    position: absolute;
    width: 190px
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li>a span.pull-right {
    position: absolute;
    right: 20px;
    top: 12px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li.active a {
    color: #f9fafc
}

[dir=rtl] #sidebar-menu .has-arrow:after {
    content: "\f0141";
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s
}

[dir=rtl] #sidebar-menu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit
}

.simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0
}

.simplebar-offset {
    direction: inherit !important;
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0 !important;
    bottom: 0;
    right: 0 !important;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch
}

.simplebar-content-wrapper {
    direction: inherit;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    visibility: visible;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    padding: 0 !important
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none
}

.simplebar-content:after,
.simplebar-content:before {
    content: " ";
    display: table
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none
}

.simplebar-height-auto-observer-wrapper {
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    -webkit-box-flex: inherit;
    -ms-flex-positive: inherit;
    flex-grow: inherit;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0
}

.simplebar-height-auto-observer {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden
}

[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
}

.simplebar-scrollbar {
    position: absolute;
    right: 2px;
    width: 6px;
    min-height: 10px
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #a2adb7;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    -webkit-transition: opacity 0s linear;
    transition: opacity 0s linear
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0
}

.hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none
}

/* metismenu, vertical bar - left side bar - end */

/* main content - start */
.main-content {
    margin-left: 260px;
    overflow: hidden;
}

.main-content .content {
    padding: 0 15px 10px 15px;
    margin-top: 70px;
}

/* main content - end */

/* avatar - at chart summary card - start */
.avatar-xs {
    height: 2rem;
    width: 2rem
}

.avatar-sm {
    height: 2.5rem;
    width: 2.5rem
}

.avatar-md {
    height: 4.5rem;
    width: 4.5rem
}

.avatar-lg {
    height: 6rem;
    width: 6rem
}

.avatar-xl {
    height: 7.5rem;
    width: 7.5rem
}

.mini-stat-icon {
    width: 46px;
    height: 46px
}

.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

/* avatar - at chart summary card - end */

/* waves effect when clicking - start */
.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent
}

.waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    background: rgba(0, 0, 0, .2);
    background: radial-gradient(rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%);
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: scale(0) translate(0, 0);
    transform: scale(0) translate(0, 0);
    pointer-events: none
}

.waves-effect.waves-light .waves-ripple {
    background: rgba(255, 255, 255, .4);
    background: radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%)
}

.waves-effect.waves-classic .waves-ripple {
    background: rgba(0, 0, 0, .2)
}

.waves-effect.waves-classic.waves-light .waves-ripple {
    background: rgba(255, 255, 255, .4)
}

.waves-notransition {
    -webkit-transition: none !important;
    transition: none !important
}

.waves-button,
.waves-circle {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%)
}

.waves-button,
.waves-button-input,
.waves-button:hover,
.waves-button:visited {
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    outline: 0;
    color: inherit;
    background-color: rgba(0, 0, 0, 0);
    font-size: 1em;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    z-index: 1
}

.waves-button {
    padding: .85em 1.1em;
    border-radius: .2em
}

.waves-button-input {
    margin: 0;
    padding: .85em 1.1em
}

.waves-input-wrapper {
    border-radius: .2em;
    vertical-align: bottom
}

.waves-input-wrapper.waves-button {
    padding: 0
}

.waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1
}

.waves-circle {
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%
}

.waves-float {
    -webkit-mask-image: none;
    -webkit-box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, .12);
    box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, .12);
    -webkit-transition: all .3s;
    transition: all .3s
}

.waves-float:active {
    -webkit-box-shadow: 0 8px 20px 1px rgba(0, 0, 0, .3);
    box-shadow: 0 8px 20px 1px rgba(0, 0, 0, .3)
}

.waves-block {
    display: block
}

.waves-effect.waves-light .waves-ripple {
    background-color: rgba(255, 255, 255, .4)
}

.waves-effect.waves-primary .waves-ripple {
    background-color: rgba(82, 92, 229, .4)
}

.waves-effect.waves-success .waves-ripple {
    background-color: rgba(35, 197, 143, .4)
}

.waves-effect.waves-info .waves-ripple {
    background-color: rgba(91, 164, 229, .4)
}

.waves-effect.waves-warning .waves-ripple {
    background-color: rgba(238, 177, 72, .4)
}

.waves-effect.waves-danger .waves-ripple {
    background-color: rgba(241, 78, 78, .4)
}

/* waves effect when clicking - end */

/* font size - start */
.font-size-10 {
    font-size: 10px !important
}

.font-size-11 {
    font-size: 11px !important
}

.font-size-12 {
    font-size: 12px !important
}

.font-size-13 {
    font-size: 13px !important
}

.font-size-14 {
    font-size: 14px !important
}

.font-size-15 {
    font-size: 15px !important
}

.font-size-16 {
    font-size: 16px !important
}

.font-size-17 {
    font-size: 17px !important
}

.font-size-18 {
    font-size: 18px !important
}

.font-size-20 {
    font-size: 20px !important
}

.font-size-22 {
    font-size: 22px !important
}

.font-size-24 {
    font-size: 24px !important
}

/* font size - end */

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.media-body h4 {
    color: var(--text-primary);
}

.popover {
    -webkit-box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .2);
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .2)
}

.w-xs {
    min-width: 80px
}

.w-sm {
    min-width: 95px
}

.w-md {
    min-width: 110px
}

.w-lg {
    min-width: 140px
}

.w-xl {
    min-width: 160px
}

.flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.dash-main-border {
    border-bottom: 1px solid #eaedf1
}

.dash-info-widget {
    background: var(--bs-body-bg);
    border-radius: 10px;
}

/* printing - start */
@media print {

    .footer,
    .navbar-header,
    .page-title-box,
    .right-bar,
    .vertical-menu {
        display: none !important
    }

    .card-body,
    .main-content,
    .page-content,
    .right-bar,
    body {
        padding: 0;
        margin: 0
    }

    .card {
        border: 0
    }
}

/* printing - end */

/* scroll */
.custom-scroll {
    height: 100%
}

/* swal2 - start */
.swal2-container .swal2-title {
    font-size: 24px;
    font-weight: 500
}

.swal2-content {
    font-size: 16px
}

.swal2-icon.swal2-question {
    border-color: #5ba4e5;
    color: #5ba4e5
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #23c58f
}

.swal2-icon.swal2-success .swal2-success-ring {
    border-color: rgba(35, 197, 143, .3)
}

.swal2-icon.swal2-warning {
    border-color: #eeb148;
    color: #eeb148
}

.swal2-styled:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.swal2-progress-steps .swal2-progress-step {
    background: #525ce5
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
    background: #525ce5
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line {
    background: rgba(82, 92, 229, .3)
}

.swal2-progress-steps .swal2-progress-step-line {
    background: #525ce5
}

.swal2-loader {
    border-color: #525ce5 transparent #525ce5 transparent
}

.symbol {
    border-color: #fff
}

/* swal2 - end */

/* apex charts - start */
.apex-charts {
    min-height: 10px !important
}

.apex-charts text {
    font-family: var(--bs-font-sans-serif) !important;
    fill: #adb5bd
}

.apex-charts .apexcharts-canvas {
    margin: 0 auto
}

.apexcharts-tooltip-text,
.apexcharts-tooltip-title {
    font-family: var(--bs-font-sans-serif) !important
}

.apexcharts-legend-series {
    font-weight: 500
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: #f8f9fa
}

.apexcharts-legend-text {
    color: #74788d !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 13px !important
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
    font-family: var(--bs-font-sans-serif) !important;
    fill: #adb5bd
}

/* apex charts - start */

/* chart legend - start */
.legend div {
    background-color: transparent !important
}

.legend tr {
    height: 30px
}

.legendLabel {
    padding-left: 5px;
    line-height: 10px;
    padding-right: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #adb5bd
}

.legendColorBox div {
    border-radius: 3px
}

.legendColorBox div div {
    border-radius: 3px
}

.float-lable-box table {
    margin: 0 auto
}

/* chart legend - end */

/* home button - at reset and recover password */
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px
}

.home-center {
    display: table;
    width: 100%;
    height: 100%
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle
}

/* @media - start */
@media (max-width:575.98px) {
    .legendLabel {
        display: none
    }
}

@media (max-width:1199.98px) {
    .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
        right: 100%;
        left: auto
    }
}

@media (max-width:991.98px) {
    .navbar-brand-box .logo-dark {
        display: block
    }

    .navbar-brand-box .logo-dark span.logo-sm {
        display: block
    }

    .navbar-brand-box .logo-light {
        display: none
    }

    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0
    }

    .topnav .navbar-nav .nav-link {
        padding: .75rem 1.1rem
    }

    .topnav .dropdown .dropdown-menu {
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-left: 20px
    }

    .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
        width: auto
    }

    .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
        margin: 0
    }

    .topnav .dropdown .dropdown-item {
        position: relative;
        background-color: transparent
    }

    .topnav .dropdown .dropdown-item.active,
    .topnav .dropdown .dropdown-item:active {
        color: #525ce5
    }

    .topnav .arrow-down::after {
        right: 15px;
        position: absolute
    }
}

@media (max-width:992px) {
    #page-topbar {
        left: 0
    }

    .navbar-brand-box {
        width: auto
    }

    .logo span.logo-lg {
        display: none
    }

    .logo span.logo-sm {
        display: inline-block
    }
}

@media (max-width:600px) {
    .navbar-header .dropdown {
        position: static
    }

    .navbar-header .dropdown .dropdown-menu {
        left: 10px !important;
        right: 10px !important
    }
}

@media (max-width:380px) {
    .navbar-brand-box {
        display: none
    }
}


@media (max-width:992px) {
    .footer {
        left: 0
    }
}

@media (max-width:992px) {
    .vertical-menu {
        display: none
    }

    .main-content {
        margin-left: 0 !important
    }

    body.sidebar-enable .vertical-menu {
        display: block
    }
}

/* @media - end */